import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrAdminSetEventView',
  computed: {
    ...mapState('scans', ['scan'])
  },
  components: {
  }
})
export default class GtrAdminSetEventView extends Vue {
  data () {
    return {
      scan2: {},
      search: '',
      companyToDelete: null
    }
  }

  @Watch('scan')
  onScanDataChange (payload: any) {
    if (payload) {
      this.$data.scan2 = payload
    }
  }

  async handleSave () {
    await this.$store.dispatch('scans/saveScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: this.$route.params.row_uuid, barcode_num: this.$data.scan2.barcode_num, line: this.$data.scan2.line })
    Container.get(Notification).success('Scan successfully saved.')
  }

  async mounted () {
    this.$store.dispatch('scans/fetchScanByUUID', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid: this.$route.params.event_uuid, row_uuid: this.$route.params.row_uuid })
  }
}
